<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		type?: "primary" | "secondary" | "outline" | "link" | "light" | "text" | "grey";
		size?: "small" | "medium" | "large";
		disabled?: boolean;
		active?: boolean;
		block?: boolean;
		danger?: boolean;
		noWrap?: boolean;
	}>(),
	{
		type: "primary",
		size: "medium",
		disabled: false,
		active: false,
		block: false,
		danger: false,
		noWrap: false
	}
);

const btnClasses = computed(() => {
	return {
		"button--primary": props.type === "primary",
		"button--secondary": props.type === "secondary",
		"button--outline": props.type === "outline",
		"button--link": props.type === "link",
		"button--light": props.type === "light",
		"button--text": props.type === "text",
		"button--grey": props.type === "grey",

		"button--disabled": props.disabled,
		"button--danger": props.danger,

		"button--size-small": props.size === "small",

		"button--no-wrap": props.noWrap,

		"button--block": props.block,
		active: props.active
	};
});
</script>

<template>
	<button
		:class="btnClasses"
		class="button"
	>
		<slot />
	</button>
</template>

<style lang="scss" scoped>
.button {
	@apply flex items-center border rounded-full px-4 py-2 gap-2
    focus:ring ring-primary ring-opacity-[.64] cursor-pointer transition-colors
    min-h-[44px] min-w-[44px] font-inter font-semibold text-sm select-none justify-center text-center leading-none;

	&--outline {
		@apply border-primary;
		&:hover,
		&:focus,
		&.active {
			@apply bg-primary bg-opacity-[.16];
		}
	}

	&--primary {
		@apply bg-primary border-primary;
		&:hover,
		&:focus,
		&.active {
			@apply bg-primarydark;
		}
	}

	&--secondary {
		@apply bg-secondary text-light;
	}

	&--link {
		@apply border-0 border-b-2 rounded-none px-2 border-dark text-dark;
	}

	&--light {
		@apply bg-light text-dark;
	}

	&--text {
		@apply border-0 bg-transparent text-dark ring-opacity-0;
	}

	&--grey {
		@apply bg-[#ABB9BF] text-dark ring-opacity-0;
	}

	&--disabled {
		@apply border-lightergrey text-lightergrey cursor-not-allowed ring-opacity-0;
	}

	&--danger {
		@apply border-red text-red;
	}

	&--size-small {
		@apply px-4 py-1 min-h-[32px] min-w-[32px] text-xs focus:ring-0 ring-0;
	}

	&--block {
		@apply w-full justify-center;
	}

	&--no-wrap {
		@apply min-w-[unset] whitespace-nowrap;
	}
}
</style>
